<template>
  <div>
    <a-row :gutter="[4, 4]">
      <a-col :span="10">
        <a-card title="客户" size="small">
          <a-row style="text-align: center; margin: 20px 0">
            <a-col :span="5">
              <a-statistic title="我的客户" :value="dataItem.client_count" />
            </a-col>
            <a-col :span="6">
              <a-statistic
                title="转化率"
                :value="(dataItem.contract_count / dataItem.client_count) * 100"
                :precision="2"
                suffix="%"
              />
            </a-col>
            <a-col :span="4">
              <a-statistic title="本周新增" :value="dataItem.weekly_new_client_count" />
            </a-col>

            <a-col :span="4">
              <a-statistic title="本月新增" :value="dataItem.monthly_new_client_count" />
            </a-col>
            <a-col :span="5">
              <a-statistic title="未来一周拜访" :value="dataItem.visits_next_week_count" />
            </a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col :span="7">
        <a-card title="合同" size="small">
          <a-row style="text-align: center; margin: 20px 0">
            <a-col :span="8">
              <a-statistic title="我的合同" :value="dataItem.contract_count" />
            </a-col>
            <a-col :span="8">
              <a-statistic title="本月新签" :value="dataItem.monthly_signed_count" />
            </a-col>
            <a-col :span="8">
              <a-statistic title="本年度新签" :value="dataItem.annual_signed_count" />
            </a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col :span="7">
        <a-card title="回款" size="small">
          <a-row style="text-align: center; margin: 20px 0">
            <a-col :span="8">
              <a-statistic title="合同总金额(元)" :value="dataItem.total_contract_amount" />
            </a-col>
            <a-col :span="8">
              <a-statistic title="已回款金额(元)" :value="dataItem.total_received_amount" />
            </a-col>
            <a-col :span="8">
              <a-statistic title="开票金额(元)" :value="dataItem.total_invoice_amount" />
            </a-col>
          </a-row>
        </a-card>
      </a-col>

      <a-col :span="12">
        <ClientStatsCard />
      </a-col>
      <a-col :span="12">
        <FollowUpStatsCard />
      </a-col>
      <a-col :span="12">
        <WechatStatsCard />
      </a-col>
      <a-col :span="12">
        <ContractStatsCard />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { dashboardOverview } from "@/apis/stats";

export default {
  components: {
    ClientStatsCard: () => import("./ClientStatsCard"),
    FollowUpStatsCard: () => import("./FollowUpStatsCard"),
    WechatStatsCard: () => import("./WechatStatsCard"),
    ContractStatsCard: () => import("./ContractStatsCard"),
  },
  data() {
    return {
      dataItem: {},
    };
  },
  computed: {},
  methods: {
    initData() {
      dashboardOverview().then((data) => (this.dataItem = data));
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
