import request from "@/utils/request";

// Dashboard
export function dashboardOverview(params) {
  return request({ url: `/dashboard/overview/`, method: "get", params });
}

export function dashboardClientStats(params) {
  return request({ url: `/dashboard/client_stats/`, method: "get", params });
}

export function dashboardFollowUpStats(params) {
  return request({ url: `/dashboard/follow_up_stats/`, method: "get", params });
}

export function dashboardWeChatStats(params) {
  return request({ url: `/dashboard/wechat_stats/`, method: "get", params });
}

export function dashboardVisitStats(params) {
  return request({ url: `/dashboard/visit_stats/`, method: "get", params });
}

export function dashboardContractStats(params) {
  return request({ url: `/dashboard/contract_stats/`, method: "get", params });
}
